html {
  scroll-snap-type: y mandatory;
}

.app {
  height: 100vh;
  width: 100vw;
  background-color: black;
  display: grid;
  place-items: center;
  margin-top: 4vh;
  margin-left: -3vw;
}

.app__videos {
  position: relative;
  height: 90vh;
  width: 100%;
  max-width: 450px;
  overflow: scroll;
  scroll-snap-type: y mandatory;
}

.app__videos::-webkit-scrollbar {
  display: none;
}
/* for IE Safari*/
.app__videos {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* General styling for the comments section */
.comments-section {
  padding: 16px;
  background-color: #121212;
  border-radius: 10px;
  color: white;
  max-width: 450px;
  margin-top: 20px;
}

.comment-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  text-transform: uppercase;
}

.comment-content {
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 5px;
}

.comment-author {
  font-weight: bold;
}

.comment-time {
  font-size: 12px;
  color: #999;
}

.comment-text {
  font-size: 14px;
  color: #ddd;
  margin-top: 5px;
}

/* Comment input area */
.comment-input-box {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.comment-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  border: none;
  outline: none;
  margin-right: 10px;
  background-color: #1e1e1e;
  color: white;
}

.comment-button {
  padding: 10px 16px;
  border-radius: 20px;
  border: none;
  background-color: #3ea6ff;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.comment-button:hover {
  background-color: #2684c7;
}


