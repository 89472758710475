/* Overall layout */
.user-landing-page {
  margin-bottom: 20px;
  padding-top: 10vh;
  padding-bottom: 15vh; /* Adjust padding for better mobile layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121212; /* Dark background */
}

.cms-form-button {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
}

.navigate-button {
  background-color: #00bcd4; /* Teal accent color */
  color: white;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  width: 85%;
  transition: background-color 0.3s ease;
}

.navigate-button:hover {
  background-color: #0288d1; /* Hover effect */
}

/* Profile Section */
.profile-section {
  background: linear-gradient(145deg, #1a1a1a, #333333); /* Dark gradient */
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6);
  padding: 2rem;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.profile-pic {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-right: 1.5rem;
  border: 4px solid #00bcd4; /* Border with teal accent */
}

.profile-info h2 {
  margin: 0;
  font-size: 2rem;
  color: #00bcd4; /* Teal color for headings */
}

.profile-info p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: #cccccc; /* Soft light gray for text */
}

/* Follow Section */
.follow-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 20px;
}

.follow-card {
  background-color: #1a1a1a;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.follow-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.7);
}

.follow-card h3 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: #00bcd4;
}

.follow-card p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff; /* White color for text */
}

/* Favorites and Subscription Sections */
.favorites-section,
.subscription-section {
  background-color: #1a1a1a;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  margin-top: 20px;
}

.favorites-section {
  grid-column: span 2;
}

.subscription-card {
  text-align: center;
}

.subscription-card h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #00bcd4; /* Teal accent color */
}

.subscription-card p {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  color: #bbbbbb; /* Muted gray text for readability */
}

/* Mobile responsiveness */
@media (max-width: 800px) {
  .user-landing-page {
    padding-bottom: 20vh; /* Reduce bottom padding for mobile */
  }

  .cms-form-button {
    width: 100%;
    margin-top: 20px;
  }

  .navigate-button {
    width: 90%; /* Increase button width for better visibility */
  }
}

@media (max-width: 768px) {
  .user-landing-page {
    grid-template-columns: 1fr;
  }

  .profile-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-pic {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .favorites-section,
  .subscription-section {
    grid-column: span 1;
  }
}
