/* UserMoviesPage.module.css */

.container {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    background-color: #f5f5f5;
    margin-top: 7vh;
    max-width: 93vw;
}

.toggleButton {
    margin-bottom: 20px;
    padding: 10px 15px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.toggleButton:hover {
    background-color: #444;
    transform: scale(1.05);
}

.navbar {
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap; /* Ensures nav items wrap on smaller screens */
}

.navList {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap; /* Allow nav items to wrap */
    justify-content: flex-start;
}

.categoryItem {
    margin-right: 20px;
    margin-bottom: 10px; /* Space between items */
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.2s;
}

.categoryItem:hover {
    background-color: #e0e0e0;
    transform: scale(1.05);
}

.contentWithSidebar,
.contentFullWidth {
    margin-top: 20px;
}

.moviesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Flexible grid */
    gap: 20px;
}

.card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.backdropImage,
.posterImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensure buttons wrap on smaller screens */
    margin-top: 15px;
}

.watchButton,
.addEpisodeButton,
.addCastButton {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 48%; /* Flexible button width */
    transition: background-color 0.3s;
    margin-bottom: 10px; /* Space between buttons */
}

.watchButton {
    background-color: #28a745;
    color: white;
}

.watchButton:hover {
    background-color: #218838;
}

.addEpisodeButton {
    background-color: #007bff;
    color: white;
}

.addEpisodeButton:hover {
    background-color: #0056b3;
}

.addCastButton {
    background-color: #ffc107;
    color: black;
}

.addCastButton:hover {
    background-color: #e0a800;
}

.modalBox {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 90%; /* Full width on smaller screens */
    margin: 5vh auto; /* Centered modal */
    overflow-y: auto; /* Ensure scrollable if content is too large */
}

.videoPlayer {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Responsive design adjustments */

/* For medium screens (tablets) */
@media (max-width: 1024px) {
    .navbar {
        flex-direction: column;
        padding: 20px;
    }

    .categoryItem {
        margin-bottom: 15px;
    }

    .moviesContainer {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

/* For smaller screens (phones) */
@media (max-width: 768px) {
    .moviesContainer {
        grid-template-columns: 1fr;
    }

    .toggleButton {
        width: 100%; /* Full width for toggle button */
    }

    .buttonContainer {
        flex-direction: column; /* Stack buttons vertically */
    }

    .watchButton,
    .addEpisodeButton,
    .addCastButton {
        width: 100%; /* Full width for buttons */
        margin-bottom: 10px;
    }

    .modalBox {
        max-width: 100%;
        padding: 15px;
    }
}
