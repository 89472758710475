.container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 100px;
    font-family: 'Arial', sans-serif;
    background-color: white;
    color: #333;
    line-height: 1.6;
  }
  
  h1, h2, h3 {
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 15px;
  }
  
  ul {
    list-style: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  hr {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #ccc;
  }
  
  @media (max-width: 600px) {
    .container {
      margin-top: 10vh;
      padding-top: 200px;
      padding: 15px;
    }
  }
  