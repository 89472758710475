.container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 100px;
    font-family: 'Arial', sans-serif;
    background-color: white;
    color: #444;
    line-height: 1.7;
}

h1,
h2,
h3 {
    color: #2c3e50;
    margin-top: 20px;
    margin-bottom: 10px;
}

p,
li {
    margin-bottom: 15px;
}

a {
    color: #2980b9;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

ul,
ol {
    margin-left: 20px;
}

.divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
}

.lastUpdated {
    font-style: italic;
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    .container {
        margin-top: 10vh;
        padding: 15px;
        padding-top: 50px;
    }
}