* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
  }

  .MuiBox-root css-18emtgw{
    border: none !important;
  }

  .MuiBox-root css-mz9etu{
    padding: 0 !important;
  }


  .MuiBox-root css-1jn0wzt{
    margin: 0 !important;
    padding: 0 !important;
  }

  .razorpay-container{
    border: none !important;
  }


  .MuiBox-root.css-ndu8ol {
    padding: 0 !important; /* Use !important to ensure it overrides any other styles */
  }

  .page-content{overflow-y:auto; overflow-x:hidden !important;}

  .MuiBox-root css-1tj1iqr{
    margin: 0 !important;
    padding: 0 !important;
  }

  MuiBox-root css-1jn0wzt{
    padding: 0 !important;
  }

  .MuiBox-root css-1tj1iqr{
    margin: 0 !important;
  }

  .MuiBox-root css-tjrupk{
    margin: 0 !important;    
  }

  
  body {
    /* Linear background color */
    background-image: linear-gradient(to right, #15171a, #15171a, #15171a);
    /* Ensure the gradient covers the whole viewport */
    min-height: 100vh;
    margin: 0;
  }
  
  .main-container {
    display: flex;
  }
  main {
    padding: 0px;
  }
  
  .title {
    font-size: 3rem;
    display: grid;
    place-items: center;
  }
  
  /* Sidebar */
  .sidebar {
    background: rgb(0, 7, 61);
    color: white;
    height: 100vh;
    overflow-y: auto;
    position: absolute;
    top:"10px"
  }
  
  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
  }
  .logo {
    font-size: 18px;
    line-height: 0;
  }
  .bars {
    width: 30px;
  }
  .hide {
    display: none;
  }

  .swiper-slide swiper-slide-active{
    margin-right: 0 ;
  }
  
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
  }
  .search input {
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    background: rgb(238, 238, 238);
  
    color: white;
  }
  
  .routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .link {
    display: flex;
    color: white;
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .link:hover {
    border-right: 4px solid white;
    background: rgb(45, 51, 89);
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .active {
    border-right: 4px solid white;
    background: rgb(45, 51, 89);
  }
  .link_text {
    white-space: nowrap;
    font-size: 15px;
  }
  
  .menu {
    display: flex;
    color: white;
  
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
  }
  .menu_item {
    display: flex;
    gap: 10px;
  }
  .menu_container {
    display: flex;
    flex-direction: column;
  }
  .menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
  }