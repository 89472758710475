.sizzlersContainer {
    display: flex;
    flex-direction: row;
    margin-top: 7.5vh;
    margin-right: 3vw;
}


.sidebar {
    flex: 0 0 300px;
    color: black;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

.filterButton {
    display: block;
    padding: 10px;
    background-color: #171818;
    width: 100%;
    color: white;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.filterButton:hover {
    background-color: #0056b3;
}

.sizzlersGrid {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.sizzlerCard {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.sizzlerCard:hover {
    transform: translateY(-10px);
}

.sizzlerChannel {
    font-size: 1.2rem;
    color: #34495e;
}

.channelLogo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.sizzlerVideo {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

.sizzlerDescription {
    font-size: 0.9rem;
    color: #7f8c8d;
    margin-bottom: 5px;
}

.sizzlerInfo {
    font-size: 0.9rem;
    color: #7f8c8d;
    margin-bottom: 5px;
}

.sizzlerInfo span {
    color: #2980b9;
}

.sizzlerStatus {
    font-weight: bold;
    color: #e74c3c;
    margin-bottom: 10px;
}

.commentsHeading {
    margin-top: 20px;
    font-size: 1.1rem;
    color: #2c3e50;
}

.commentsList {
    padding-left: 20px;
}

.commentItem {
    background-color: #ecf0f1;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.commentText {
    color: #34495e;
}

.commentAuthor {
    font-size: 0.85rem;
    color: #7f8c8d;
    margin-top: 5px;
}

.loadingMsg,
.errorMsg,
.noSizzlersMsg,
.noCommentsMsg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 5rem;
    color: black;
    height: 100vh; /* Full viewport height to center vertically */
    margin: 0; /* Remove margin to avoid shifting */
}

.errorContainer{
    background-color: white;
}

.showMore {
    color: #3498db;
    cursor: pointer;
    font-size: 0.9rem;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.modalOverlay {
    background-color: rgba(0, 0, 0, 0.75);
}

.closeModalBtn {
    float: right;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.modalCommentsList {
    list-style-type: none;
    padding-left: 0;
}

.modalCommentItem {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.blankPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #f0f0f0; /* Light background color */
    text-align: center;
  }
  
  .loginButton {
    padding: 20px 40px; /* Adjust padding for a larger button */
    margin-top: 30vh; /* Space above the button */
    color: white;
    background-color: black; /* Dark background for contrast */
    border: none; /* Remove default border */
    border-radius: 5px; /* Slightly rounded corners */
    font-size: 16px; /* Adjust font size */
    cursor: pointer; /* Change cursor to pointer */
    transition: background-color 0.3s, transform 0.3s; /* Add smooth transitions */
  }
  
  .loginButton:hover {
    background-color: #444; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
  }
  
  .welcomeMessage {
    font-size: 24px; /* Larger font size for the welcome message */
    color: #333; /* Dark text color */
  }


@media (max-width: 768px) {
    .sizzlersContainer {
        flex-direction: column;
    }

    .sidebar {
        margin-top: 10vh;
        margin-bottom: 20px;
    }
}
