/* Overall Container */
.continueWatching {
    padding: 100px 20px;
    background-color: #111;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .noMedia {
    text-align: center;
    color: #fff;
    margin-top: 20px;
    font-size: 1rem;
  }

 
  
  /* Main Title */
  .mainTitle {
    font-size: 1.8rem;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 600;
    background-image: linear-gradient(to right, #ffffff, #d1d1d1);
    background-clip: text;            /* Standard property */
    -webkit-background-clip: text;    /* WebKit-based browsers */
    -webkit-text-fill-color: transparent;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  
  /* Category Section */
  .categorySection {
    margin-bottom: 40px;
  }
  
  /* Category Title with Gradient Text */
  .categoryTitle {
    font-size: 1.5rem;
    margin: 0 20px 20px;
    font-weight: 500;
    color: transparent;
    background-image: linear-gradient(to right, #ffffff 0%, #000000 51%, #F7971E 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Horizontal Media Scroller */
  .mediaScroller {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    padding: 0 20px 10px;
    scroll-behavior: smooth;
  }
  
  /* Media Item */
  .mediaItem {
    width: 220px;
    flex-shrink: 0;
    border-radius: 12px;
    transition: transform 0.3s ease;
    text-decoration: none;
    color: inherit;
  }
  
  .mediaItem:hover {
    transform: translateY(-5px);
  }
  
  .imageContainer {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    /* aspect-ratio: 2/3; */
  }
  
  .poster {
    width: 100%;
    height: auto;
    border-radius: 12px;
    border: 0.5px solid white;
    box-sizing: border-box; /* Include border in dimensions */
    object-fit: contain;  /* Or consider "cover" if you want to fill the container */
    transition: transform 0.3s ease;
  }
  
  .mediaItem:hover .poster {
    transform: scale(1.05);
  }
  
  /* Progress Bar */
  .progressBar {
    position: absolute;
    bottom: 60px;
    left: 10px;
    right: 10px;
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    overflow: hidden;
  }
  
  /* Progress Fill */
  .progress {
    height: 100%;
    background-image: linear-gradient(to right, #F7971E 0%, #FFD200 51%, #F7971E 100%);
    transition: width 0.3s ease;
  }
  
  /* Overlay Text on Progress Bar */
  .progressInfo {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.85rem;
    background: rgba(0, 0, 0, 0.7);
    padding: 2px 6px;
    border-radius: 4px;
    color: #fff;
    white-space: nowrap;
  }
  
  /* Last Synced Information (Cool Name) */
  .lastSynced {
    position: absolute;
    top: 25px;
    left: 10px;
    font-size: 0.5rem;
    background: rgba(0, 0, 0, 0.7);
    color: #ccc;
    padding: 2px 6px;
    border-radius: 4px;
  }
  
  /* Media Item Title with Gradient Text */
  .title {
    position: absolute;
    bottom: 25px;
    left: 10px;
    font-size: 1rem;
    font-weight: 600;
    color: transparent;
    background-image: linear-gradient(to right, #F7971E 0%, #FFD200 51%, #F7971E 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  /* Optional: Scrollbar and Loading styles can be added here if needed */
  