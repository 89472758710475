.login_container {
	width: 100%;
	height: 100vh; /* Make the container fill the entire viewport height */
	background-color: rgb(243, 242, 242);
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .surround_login_container {
	width: 100%;
	height: 100%;
	max-width: 900px; /* Restrict the maximum width to maintain design */
	max-height: 500px; /* Restrict the maximum height to maintain design */
	background-color: rgba(245, 245, 245, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url('../../../assets/light.avif');
	background-size: cover;
	background-position: center;
  }
  
  .login_form_container {
	width: 100%;
	height: 100%;
	display: flex;
	background-color: rgba(245, 245, 245, 0.1);
	border-radius: 10px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
	  0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }
  
  .left {
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(245, 245, 245, 0.335);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	padding: 20px;
  }
  
  .form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .form_container h1 {
	font-size: 40px;
	margin-top: 0;
	color: #000000;
	font-weight: bold;
  }
  
  .input {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #edf5f3;
	border: 2px solid black;
	margin: 5px 0;
	font-size: 14px;
  }
  
  .error_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
  }
  
  .right {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #00000090;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 20px;
  }
  
  .right h1 {
	margin-top: 0;
	color: white;
	font-size: 40px;
	text-align: center;
  }
  
  .white_btn,
  .green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
  }
  
  .green_btn {
	background-color: #6495ed;
	color: white;
	margin: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 900px) {
	.surround_login_container, .login_form_container {
	  flex-direction: column;
	  max-width: none;
	  max-height: none;
	  padding: 20px;
	}
  
	.left, .right {
	  width: 100%;
	  border-radius: 0;
	  padding: 20px;
	}
  
	.input {
	  width: 100%;
	}
  
	.right h1, .form_container h1 {
	  font-size: 32px;
	}
  
	.white_btn, .green_btn {
	  width: 100%;
	  padding: 10px;
	  margin-top: 10px;
	}
  }
  
  @media (max-width: 600px) {
	.surround_login_container {
	  padding: 10px;
	}
  
	.left, .right {
	  padding: 10px;
	}
  
	.form_container h1, .right h1 {
	  font-size: 28px;
	}
  
	.input {
	  padding: 12px;
	}
  
	.white_btn, .green_btn {
	  padding: 10px;
	}
  }
  