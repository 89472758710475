.video {
  position: relative;
  width: 100%; /* Ensures the video takes up the entire available width */
  height: 90vh;
  scroll-snap-align: start;
  border-radius: 20px;
}

.video__player {
  object-fit: fill;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.shortsVideoTop {
  position: absolute;
  min-width: fit-content;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.shortsVideoTopIcon {
  position: relative;
  /* display: flex; */
}

.shortsVideoTopIcon > .MuiSvgIcon-root {
  font-size: 32px;
  padding: 10px;
}

.shortsVideoSideIcons {
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shortsVideoSideIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shortsVideoSideIcon > p {
  color: white;
}

.shortsVideoSideIcon > .MuiSvgIcon-root {
  padding: 10px;
  font-size: 28px;
}

.shortsBottom {
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: 10px;
}

.shortsDesc {
  display: flex;
  padding: 10px;
}

.shortsDesc > p {
  color: white;
}

.MuiSvgIcon-root {
  cursor: pointer;
  color: white;
}

.shortDetails {
  display: flex;
  align-items: center;
  padding: 10px;
}

.shortDetails > p {
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  font-weight: 500;
}

.shortDetails > button {
  padding: 7px;
  background-color: rgba(177, 177, 177, 0.609);
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}

.shortDetails > button:hover {
  background-color: rgb(0, 0, 0);
}

.shortsVideoSideIcon > .MuiSvgIcon-root:hover {
  color: blue;
}

.shortsDesc > .ticker {
  height: fit-content;
  width: 100%;
  max-width: 250px;
}

.description {
  color: white;
}

.shortsVideoSideIcon > .MuiSvgIcon-root:last-child {
  animation: spinTheRecord infinite 5s linear;
  bottom: 0;
  right: 20px;
  font-size: 32px;
}

.commentsSection {
  margin-top: 20px;
  max-height: 150px; /* Set a fixed height */
  overflow-y: scroll; /* Enable scrolling */
  padding-right: 10px; /* Add padding to avoid content being hidden by scrollbar */
}

/* Customize scrollbar for WebKit browsers (Chrome, Safari) */
.commentsSection::-webkit-scrollbar {
  width: 5px; /* Thinner scrollbar */
}

.commentsSection::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Transparent track with light background */
  border-radius: 10px; /* Rounded track edges */
}

.commentsSection::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4); /* Slightly transparent thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Border around the thumb for a better look */
}

.commentsSection::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6); /* Darker on hover for better visibility */
  border: 1px solid rgba(255, 255, 255, 0.4); /* Slightly more pronounced border on hover */
}

/* Customize scrollbar for Firefox */
.commentsSection {
  scrollbar-width: thin; /* Thinner scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1); /* Thumb and track colors */
}


.comment {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}

.comment p {
  margin-left: 10px;
  color: white;
}


.toggleCommentsButton {
  background-color: #00000059;
  color: white;
  padding: 5px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

.toggleCommentsButton:hover {
  background-color: #00000059;
}

.newCommentSection {
  display: flex;
  margin-top: 10px;
}

.newCommentSection button {
  padding: 10px 15px;
  background-color: #1da1f2;
  color: white;
  border: none;
  cursor: pointer;
}

.newCommentSection input:focus {
  outline: none;
}

.newCommentSection button {
  padding: 10px 15px;
  background-color: #000000;
  color: white;
  border: none;
  /* border-radius: 20px; */
  cursor: pointer;
}

.newCommentSection button:hover {
  background-color: #000000;
}

.dropdown {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100; /* Ensure it appears above other elements */
}

.dropdown p {
  margin: 0;
  color: #555;
}

@keyframes spinTheRecord {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media queries to handle screen sizes smaller than 450px */
@media (max-width: 450px) {
  .app__videos {
    max-width: 100%; /* Ensures the video container takes the full width on smaller screens */
  }

  .video {
    width: 100%; /* The video will fill the available container width */
  }
  
  .video__player {
    border-radius: 10px; /* Keep the video player rounded on smaller screens */
  }
}