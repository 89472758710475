/* UserPosts.module.css */
.postsContainer {
    display: flex;
    flex-direction: row;
    /* padding: 20px; */
    margin-top: 7.5vh;
    margin-right: 3vw;
}

.sidebar {
    flex: 0 0 300px;  /* Set a fixed width */
    color: black;
    padding: 20px;
    background-color: #f0f0f0;
    /* margin-right: 20px;  Reduce margin */
    border-radius: 8px;
}

.filterButton {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #171818;
    color: white;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.filterButton:hover {
    background-color: #0056b3;
}

.heading {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
}

.postsGrid {
    flex: 1;  /* Allow it to fill the remaining space */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.postCard {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.postCard:hover {
    transform: translateY(-10px);
}

.postCaption {
    font-size: 1.2rem;
    color: #34495e;
    margin-bottom: 10px;
}

.postVideo, .postImage {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

.postInfo {
    font-size: 0.9rem;
    color: #7f8c8d;
    margin-bottom: 5px;
}

.postInfo span {
    color: #2980b9;
}

.postStatus {
    font-weight: bold;
    color: #e74c3c;
    margin-bottom: 10px;
}

.commentsHeading {
    margin-top: 20px;
    font-size: 1.1rem;
    color: #2c3e50;
}

.commentsList {
    padding-left: 20px;
}

.commentItem {
    background-color: #ecf0f1;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.commentText {
    color: #34495e;
}

.commentAuthor {
    font-size: 0.85rem;
    color: #7f8c8d;
    margin-top: 5px;
}

.loadingMsg,
.errorMsg,
.noPostsMsg,
.noCommentsMsg {
    text-align: center;
    font-size: 1.1rem;
    color: #e74c3c;
}

@media (max-width: 768px) {
    .postsContainer {
        flex-direction: column;
    }

    .sidebar {
        margin-top: 10vh;
        margin-bottom: 20px;
    }
}
