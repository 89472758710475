/* Global Container Styles */
.signup_container {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #e9ecef; /* Subtle gray background for professionalism */
  }
  
  /* Form Container */
  .signup_form_container {
	width: 100%;
	max-width: 900px;
	min-height: 600px;
	display: flex;
	border-radius: 10px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
	/* background-image: url('../../../assets/whiteroll.avif'); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	overflow: hidden;
  }
  
  /* Left Section */
  .left, .right {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }
  
  .left {
	background-color: rgba(44, 62, 80, 0.9); /* Deep blue-gray for professionalism */
	color: white;
	padding: 30px;
	text-align: center;
  }
  
  .left h1 {
	font-size: 2.2rem;
	margin-bottom: 20px;
  }
  
  /* Right Section */
  .right {
	padding: 40px;
	background-color: #ffffff; /* Clean white background */
  }
  
  .right h1 {
	font-size: 1.8rem;
	color: #2c3e50; /* Text color that matches the left section */
	margin-bottom: 20px;
  }
  
  /* Form Styling */
  .form_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
  }
  
  /* Input Styling */
  .input {
	width: 100%;
	padding: 12px;
	margin: 10px 0;
	border-radius: 5px;
	border: 1px solid #ced4da; /* Neutral border color */
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
	transition: border-color 0.3s;
  }
  
  .input:focus {
	border-color: #495057; /* Darker focus color */
	outline: none;
  }
  
  /* Specific styling for OTP and email inputs to maintain alignment */
  .email_input_container, .inputverify {
	display: flex;
	align-items: center;
	margin: 10px 0;
  }
  
  .email_input_container input, .inputverify input {
	flex: 1;
	padding: 12px;
	border-radius: 5px 0 0 5px;
	border: 1px solid #ced4da;
	border-right: none;
  }
  
  .email_input_container .view_password_btn, .inputverify .view_password_btn {
	padding: 12px;
	background-color: #f8f9fa;
	border-radius: 0 5px 5px 0;
	border: 1px solid #ced4da;
	cursor: pointer;
  }
  
  /* Button Styles */
  button {
	padding: 12px;
	border: none;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	transition: background-color 0.3s;
	margin: 10px 0;
  }
  
  .send_otp_btn, .verify_otp_btn, .green_btn {
	background-color: #007bff; /* Professional blue */
	color: white;
	font-weight: bold;
  }
  
  .send_otp_btn:hover, .verify_otp_btn:hover, .green_btn:hover {
	background-color: #0056b3; /* Darker blue for hover effect */
  }
  
  .verified_otp_btn {
	background-color: #28a745; /* Green for verified state */
	color: white;
	cursor: not-allowed;
  }
  
  /* Additional Styles */
  .error_msg {
	width: 100%;
	padding: 10px;
	background-color: #dc3545; /* Red for error messages */
	color: white;
	text-align: center;
	border-radius: 5px;
  }
  
  .success_msg {
	color: #28a745; /* Green for success message */
	margin-top: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
	.signup_form_container {
	  flex-direction: column;
	}
  
	.left, .right {
	  border-radius: 0;
	  padding: 20px;
	}
  
	.left h1, .right h1 {
	  font-size: 1.5rem;
	}
  }
  