@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #181818;
  color: #ffffff;
}

.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(135deg, #0b0c10, #1f2833);
}

h1 {
  margin-top: 20vh;
  font-size: 2.5rem;
  color: #66fcf1;
  margin-bottom: 30px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.subscription-plans {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.plan-card {
  background-color: #1f2833;
  border: 2px solid #45a29e;
  border-radius: 8px;
  padding: 20px;
  width: 280px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.plan-card.selected {
  border-color: #66fcf1;
  box-shadow: 0 8px 16px rgba(102, 252, 241, 0.3);
  transform: translateY(-10px);
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.plan-header {
  font-size: 1.5rem;
  color: #66fcf1;
  margin-bottom: 15px;
}

.plan-price {
  font-size: 1.2rem;
  color: #45a29e;
  margin-bottom: 15px;
}

.plan-features {
  list-style: none;
  padding: 0;
  color: #c5c6c7;
  margin-bottom: 20px;
}

.plan-features li {
  margin-bottom: 10px;
}

.plan-select {
  background-color: #45a29e;
  color: #0b0c10;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.plan-select:hover {
  background-color: #66fcf1;
}

.plan-select:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 20px;
}
